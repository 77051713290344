import { useEffect, useState } from "react";
import { Text, Paper } from "@mantine/core";

type Props = {};

type Time = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};
const Counting = (props: Props) => {
  const calculateTime = () => {
    const difference: number = +new Date() - +new Date(`10/22/2021`);

    const time: Time = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };

    return time;
  };

  const [time, setTime] = useState(calculateTime());

  useEffect(() => {
    setTimeout(() => {
      setTime(calculateTime());
    }, 1000);
  });

  return (
    <Paper shadow="xl" radius="md" p="md" withBorder>
      <Text weight={500} color="dimmed">
        {" "}
        <span role="img" aria-label="leo">
          🦁
        </span>
        和
        <span role="img" aria-label="dog">
          🐟
        </span>
        一起走过
        {time.days}天{time.hours}时{time.minutes}分{time.seconds}秒啦～
      </Text>
    </Paper>
  );
};

export default Counting;
