import { Button } from "@mantine/core";
import { IconBrandGoogle } from "@tabler/icons";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { auth } from "../api/Firebase";

type Props = {};

const SignIn = (props: Props) => {
  const signInWithGoogle: () => void = () => {
    const provider: GoogleAuthProvider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  return (
    <Button
      size="lg"
      radius="md"
      variant="gradient"
      gradient={{ from: "blue", to: "pink" }}
      leftIcon={<IconBrandGoogle stroke={3} />}
      onClick={signInWithGoogle}
    >
      使用Google账户登陆
    </Button>
  );
};

export default SignIn;
