import { Button } from "@mantine/core";
import { IconLogout } from "@tabler/icons";

import { auth } from "../api/Firebase";

type Props = {};

const SignOut = (props: Props) => {
  return (
    auth.currentUser && (
      <Button
        size="lg"
        radius="md"
        variant="gradient"
        gradient={{ from: "blue", to: "pink" }}
        onClick={() => auth.signOut()}
        leftIcon={<IconLogout />}
      >
        退出登录
      </Button>
    )
  );
};

export default SignOut;
