import { useEffect, useState } from "react";
import { createStyles, Container, Text, Title } from "@mantine/core";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../api/Firebase";
import SignIn from "./SignIn";
import SignOut from "./SignOut";
//import FirstTime from "./FirstTime";
import Counting from "./Counting";

const BREAKPOINT = "@media (max-width: 755px)";

type Props = {};

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    boxSizing: "border-box",
    // TODO: Apply theming
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },

  inner: {
    position: "relative",
    paddingTop: 200,
    paddingBottom: 120,

    [BREAKPOINT]: {
      paddingTop: 80,
      paddingBottom: 80,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 72,
    fontWeight: 900,
    lineHeight: 1.2,
    margin: 0,
    padding: 0,
    // TODO: Apply theming
    color: theme.colorScheme === "dark" ? theme.white : theme.black,

    [BREAKPOINT]: {
      fontSize: 36,
      // lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: 24,

    [BREAKPOINT]: {
      fontSize: 16,
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 1.25,

    [BREAKPOINT]: {
      marginTop: theme.spacing.xl,
    },
  },
}));

const Landing = (props: Props) => {
  const [user, loading, error] = useAuthState(auth);
  const { classes } = useStyles();

  const date: Date = new Date();
  const hours: number = date.getHours();

  const [name, setName] = useState("神秘人");
  const [title, setTitle] = useState("欢迎来到");

  useEffect(() => {
    if (user?.displayName) {
      if (user.email === "itsjoeoui@gmail.com") {
        setName("🐟宝宝");
        setTitle("欢迎回家～💛");
      } else if (user.email === "ltong4368@gmail.com") {
        setName("🦁️宝宝");
        setTitle("欢迎回家～💛");
      } else {
        setName(user.displayName);
      }
    } else {
      setName("神秘人");
      setTitle("欢迎来到");
    }
  }, [user]);

  if (error) {
    return <div>{error.name + ": " + error.message}</div>;
  }

  return loading ? (
    <div></div>
  ) : (
    <div className={classes.wrapper}>
      <Container size={700} className={classes.inner}>
        <Text weight={500} className={classes.description}>
          {hours < 12
            ? "早上好"
            : hours <= 18 && hours >= 12
            ? "下午好"
            : "晚上好"}
          , {name}!
        </Text>

        <Title className={classes.title}>
          {title}
          <br />
          <Text
            component="span"
            variant="gradient"
            gradient={{ from: "blue", to: "pink" }}
            inherit
          >
            leoandgemini.love
          </Text>{" "}
        </Title>

        <div className={classes.description}>
          <Counting />
        </div>

        <div className={classes.controls}>
          {user ? <SignOut /> : <SignIn />}
        </div>

        <div className={classes.controls}>
          {user ? (
            //            <FirstTime />
            <Text size="lg" weight={400}>
              小🐟还在施工www 请注意脚下安全🥺
            </Text>
          ) : (
            <Text size="lg" weight={400}>
              请登录查看更多内容:P
            </Text>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Landing;
